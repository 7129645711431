var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-shield-account", title: "Perfil de acesso" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showCreate = !_vm.showCreate
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Cadastrar perfil ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  "return-object": "",
                  items: _vm.roles,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Selecione o perfil",
                  loading: _vm.loadingRoles,
                  disabled: _vm.loadingRoles,
                  solo: ""
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              }),
              _c("span", { staticClass: "caption" }, [
                _vm._v("*O perfil é exibido de acordo com a instituição.")
              ])
            ],
            1
          )
        ],
        1
      ),
      false
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "elevation-0",
                      attrs: { color: "grey lighten-3 grey--text", dense: "" }
                    },
                    [_c("v-toolbar-title", [_vm._v("Permissões gerais")])],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("Opção")]),
                                      _c("th", [_vm._v("Visualizar")]),
                                      _c("th", [_vm._v("Cadastrar")]),
                                      _c("th", [_vm._v("Editar")]),
                                      _c("th", [_vm._v("Excluir")])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.selected.permissions, function(
                                      item,
                                      i
                                    ) {
                                      return _c("tr", { key: i }, [
                                        _c("td", [_vm._v(_vm._s(item.name))]),
                                        _c(
                                          "td",
                                          [
                                            _c("v-switch", {
                                              attrs: { flat: "" },
                                              model: {
                                                value: item.ready,
                                                callback: function($$v) {
                                                  _vm.$set(item, "ready", $$v)
                                                },
                                                expression: "item.ready"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-switch", {
                                              attrs: { centered: "", flat: "" },
                                              model: {
                                                value: item.create,
                                                callback: function($$v) {
                                                  _vm.$set(item, "create", $$v)
                                                },
                                                expression: "item.create"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-switch", {
                                              attrs: { flat: "" },
                                              model: {
                                                value: item.update,
                                                callback: function($$v) {
                                                  _vm.$set(item, "update", $$v)
                                                },
                                                expression: "item.update"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-switch", {
                                              attrs: { flat: "" },
                                              model: {
                                                value: item.delete,
                                                callback: function($$v) {
                                                  _vm.$set(item, "delete", $$v)
                                                },
                                                expression: "item.delete"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2367048149
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _vm.selected.id
            ? _c(
                "v-tabs",
                { attrs: { color: "blue darken-3", right: "" } },
                [
                  _c(
                    "v-tab",
                    [
                      _vm._v("Menu "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "green", small: "" }
                        },
                        [_vm._v("mdi-sitemap")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    [
                      _vm._v("Autorizações "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "green", small: "" }
                        },
                        [_vm._v("mdi-lock-open-check")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    [
                      _vm._v("Dashboards "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "green", small: "" }
                        },
                        [_vm._v("mdi-desktop-mac-dashboard")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "1" },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          !_vm.selected.is_admin && !_vm.selected.is_master
                            ? _c(
                                "v-card",
                                { attrs: { outlined: "" } },
                                [
                                  _c(
                                    "v-app-bar",
                                    {
                                      staticClass: "elevation-0",
                                      attrs: {
                                        color: "grey lighten-3 grey--text",
                                        dense: ""
                                      }
                                    },
                                    [
                                      _c("v-toolbar-title", [
                                        _vm._v("Permissão em menu")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-treeview", {
                                        attrs: {
                                          selectable: "",
                                          items: _vm.menus,
                                          "selection-type": "independent"
                                        },
                                        model: {
                                          value: _vm.selected.menus,
                                          callback: function($$v) {
                                            _vm.$set(_vm.selected, "menus", $$v)
                                          },
                                          expression: "selected.menus"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-alert",
                                {
                                  attrs: {
                                    dense: "",
                                    text: "",
                                    type: _vm.selected.is_admin
                                      ? "success"
                                      : "info"
                                  }
                                },
                                [
                                  _vm._v("Esse perfil é "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.selected.name))
                                  ]),
                                  _vm._v(
                                    " e já possui acesso à todos os menus."
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "2", staticClass: "pa-2" },
                    [
                      !_vm.selected.is_admin
                        ? _c(
                            "v-row",
                            _vm._l(_vm.instances, function(instance, i) {
                              return _c(
                                "v-col",
                                {
                                  key: i,
                                  attrs: { cols: "12", sm: "6", md: "6" }
                                },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        outlined: "",
                                        border: "top",
                                        color: "grey"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "subheading grey--text text--darken-2"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(instance.name) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "caption grey--text" },
                                        [_vm._v(_vm._s(instance.description))]
                                      ),
                                      _c(
                                        "v-row",
                                        _vm._l(instance.permissions, function(
                                          permission,
                                          j
                                        ) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: j,
                                              staticClass: "mt-2",
                                              attrs: {
                                                cols: "12",
                                                sm: "3",
                                                md: "3"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticClass:
                                                  "text-capitalize pt-0 pb-2 mt-0",
                                                attrs: {
                                                  label: permission.name,
                                                  color: "green darken-3",
                                                  value: permission.id,
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.selected.permissions,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selected,
                                                      "permissions",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selected.permissions"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "caption grey--text text-end ma-0 pa-0"
                                        },
                                        [
                                          _vm._v(
                                            "Obs: Selecione as opções para permissão"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c(
                            "v-alert",
                            { attrs: { dense: "", text: "", type: "success" } },
                            [
                              _vm._v("Esse perfil é "),
                              _c("strong", [_vm._v(_vm._s(_vm.selected.name))]),
                              _vm._v(" e já possui todos os acessos.")
                            ]
                          )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "3" },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          !_vm.selected.is_admin
                            ? _c(
                                "v-card",
                                { attrs: { outlined: "" } },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.charts, function(chart, i) {
                                      return _c(
                                        "v-list-item",
                                        { key: i },
                                        [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { value: chart.id },
                                                model: {
                                                  value: _vm.selected.charts,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selected,
                                                      "charts",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "selected.charts"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(chart.name))
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-alert",
                                {
                                  attrs: {
                                    dense: "",
                                    text: "",
                                    type: "success"
                                  }
                                },
                                [
                                  _vm._v("Esse perfil é "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.selected.name))
                                  ]),
                                  _vm._v(" e já possui todos os acessos.")
                                ]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.showCreate,
            callback: function($$v) {
              _vm.showCreate = $$v
            },
            expression: "showCreate"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Cadastrar novo perfil")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  autofocus: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.name,
                                  label: "Nome*",
                                  required: ""
                                },
                                model: {
                                  value: _vm.role.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.role, "name", $$v)
                                  },
                                  expression: "role.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: { label: "Descrição" },
                                model: {
                                  value: _vm.role.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.role, "description", $$v)
                                  },
                                  expression: "role.description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("small", [_vm._v("*Campos obrigatórios")])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showCreate = !_vm.showCreate
                        }
                      }
                    },
                    [_vm._v("Fechar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.loadingCreate,
                        disabled: _vm.loadingCreate
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          _vm.createRole()
                          _vm.loadingCreate = true
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("save")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.loadingSaveRole,
                    disabled: _vm.loadingSaveRole
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.savePermissions()
                    }
                  }
                },
                [
                  _vm._v("Atualizar"),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-content-save")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }